import { UserRole, RequestStatus } from "../interfaces";

export const getRequestStatus = (status: string) => {
  switch (status) {
    case RequestStatus.OPEN:
      return "Open";
    case RequestStatus.CLOSED:
      return "Closed";
    case RequestStatus.PROCESSING:
      return "Processing";
    case RequestStatus.RESOLVED:
      return "Resolved";
    default:
      return "";
  }
};

export const getRoleName = (role?: string) => {
  switch (role) {
    case UserRole.SUPER_ADMIN:
      return "Super Admin";
    case UserRole.MANAGER:
      return "Manager";
    case UserRole.TEACHER:
      return "Teacher";
    default:
      return "Student";
  }
};
