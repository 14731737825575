import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { BatchResponse, BatchesResponse, Course } from "../../interfaces";
import { toastMessage } from "../../utils/toastError";
import { PaginationDto } from "../../interfaces/dto/default.dto";

export const batchApi = createApi({
  reducerPath: "batchApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/batch",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getBatches: build.query<BatchesResponse, {}>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
    }),
    downloadBatchReport: build.query<any, {}>({
      query: (params) => ({
        url: "/report",
        method: "GET",
        params,
      }),
    }),
    getBatch: build.query<BatchResponse, {}>({
      query: (id) => ({
        url: "/" + id,
        method: "GET",
      }),
    }),

    createCourse: build.mutation<Course, { batch_id: string; course_fee: number; description: string }>({
      query: (body) => ({
        url: "/course",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.resolve(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Course) {
        toastMessage("success", "Successfully created");
        return baseQueryReturnValue;
      },
    }),
    updateCourse: build.mutation<Course, { id: string; batch_id: string; course_fee: number; description: string }>({
      query: (body) => ({
        url: "/course",
        method: "PUT",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.resolve(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Course) {
        toastMessage("success", "Successfully updated");
        return baseQueryReturnValue;
      },
    }),
    deleteCourse: build.mutation<Course, { id: string }>({
      query: (body) => ({
        url: "/course",
        method: "DELETE",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.resolve(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Course) {
        toastMessage("success", "Successfully deleted");
        return baseQueryReturnValue;
      },
    }),
    getCourses: build.query<{ entries: Course[]; total: number }, PaginationDto>({
      query: (params) => ({
        url: "/course",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useGetBatchesQuery,
  useLazyGetBatchesQuery,
  useGetBatchQuery,
  useLazyGetBatchQuery,
  useLazyDownloadBatchReportQuery,
  useCreateCourseMutation,
  useGetCoursesQuery,
  useUpdateCourseMutation,
  useDeleteCourseMutation,
} = batchApi;
