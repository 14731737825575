import React, { useEffect } from "react";

import { PageWrapper } from "../components";
import { useAppSelector } from "../hooks";

interface WrapperType {
  children: React.ReactNode;
}

export const Wrapper = (props: WrapperType) => {
  const isAuthenticated = useAppSelector((state) => !!state.auth.loggedIn);
  const { title, loading } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (isAuthenticated) {
      document.body.className = "bg-slate-200";
    } else {
      document.body.className = "bg-white";
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return (
      <PageWrapper title={title} loading={loading} visible={true}>
        {props.children}
      </PageWrapper>
    );
  }

  return <div>{props.children}</div>;
};
