import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

interface ISearch {
  className?: string;
  value: string;
  defaultValue?: string;
  onChange: Function;
  searchOptions: string[];
  onClick: Function;
  onFilterChange: Function;
  onEnter: Function;
}

export function Search({
  value,
  onChange,
  defaultValue,
  className,
  searchOptions,
  onClick,
  onFilterChange,
  onEnter,
}: ISearch) {
  return (
    <div className={'flex justify-end items-center gap-2 ' + className}>
      <div className="border rounded-md flex  ">
        <p className="p-2 bg-gray-300">Filter by</p>
        <select
          className="focus:outline-none"
          name="search-options"
          id="search-optoins"
          onChange={(e) => {
            onFilterChange(e);
          }}
          defaultValue={defaultValue}
        >
          {searchOptions.map((e, i) => {
            return (
              <option key={i} value={e}>
                {e}
              </option>
            );
          })}
        </select>
      </div>
      <div className="flex items-center">
        <input
          className="focus:outline-none border rounded-md mx-2 p-2"
          type="text"
          value={value}
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') onEnter();
          }}
        />
        <AiOutlineSearch
          className="text-xl cursor-pointer"
          onClick={() => {
            onClick();
          }}
        />
      </div>
    </div>
  );
}
