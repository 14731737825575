import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import {
  Announcement,
  CreateAnnouncementDto,
  GetAnnouncementDto,
  GetAnnouncementResponse,
  UpdateAnnouncementDto,
} from "../../interfaces";
import { toastMessage } from "../../utils/toastError";

export const announcementApi = createApi({
  reducerPath: "announcementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/announcement",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getAnnouncements: build.query<GetAnnouncementResponse, Partial<GetAnnouncementDto>>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
    }),
    createAnnouncement: build.mutation<Announcement, CreateAnnouncementDto>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
    updateAnnouncement: build.mutation<any, Partial<UpdateAnnouncementDto>>({
      query: ({ id, ...body }) => ({
        url: "/" + id,
        method: "PUT",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse() {
        toastMessage("success", "Updated successfully");
      },
    }),
    getAnnouncement: build.query<Announcement, string>({
      query: (id) => ({
        url: "/" + id,
        method: "GET",
      }),
    }),
    deleteAnnouncement: build.mutation<Announcement, string>({
      query: (id) => ({
        url: "/" + id,
        method: "DELETE",
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
  }),
});

export const {
  useGetAnnouncementsQuery,
  useGetAnnouncementQuery,
  useCreateAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useDeleteAnnouncementMutation,
} = announcementApi;
