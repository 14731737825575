import React, { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { PageLoader } from "../components";
import { Wrapper } from "../container/Wrapper";

import { AuthRoute } from "./AuthRoute";
import { PrivateRoute, ProtectedRoute } from "./PrivateRoute";
import { UserRole } from "../interfaces";

// Admin Pages
const AdminLogin = React.lazy(() => import("../pages/AdminLogin"));
const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));

// Student Module
const StudentList = React.lazy(() => import("../pages/student/StudentList"));
const StudentSingle = React.lazy(() => import("../pages/student/StudentSingle"));

// Batch Module
const BatchList = React.lazy(() => import("../pages/batch/BatchList"));
const BatchSingle = React.lazy(() => import("../pages/batch/BatchSingle"));

// Course Module
const CourseList = React.lazy(() => import("../pages/course/CourseList"));

// Request Module
const RequestList = React.lazy(() => import("../pages/request/RequestList"));
const RequestSingle = React.lazy(() => import("../pages/request/RequestSingle"));

// Member Module
const MemberList = React.lazy(() => import("../pages/member/MemberList"));
const MemberSingle = React.lazy(() => import("../pages/member/MemberSingle"));
const MemberProfile = React.lazy(() => import("../pages/member/MemberProfile"));

// Activity Log Module
const ActivityLogs = React.lazy(() => import("../pages/ActivityLogs"));

// Announcement Module
const AnnouncementList = React.lazy(() => import("../pages/announce/AnnouncementList"));

// Attendance Module
const AttendanceList = React.lazy(() => import("../pages/attendance/AttendanceList"));

// Performance Module
const PerformanceList = React.lazy(() => import("../pages/performance/PerformanceList"));

// Question Module
const QuestionList = React.lazy(() => import("../pages/question/QuestionList"));
const QuestionCreate = React.lazy(() => import("../pages/question/QuestionCreate"));

const Payments = React.lazy(() => import("../pages/Payments"));
const FulfilmentSummery = React.lazy(() => import("../pages/FulfilmentSummery"));
const FulfilmentPayment = React.lazy(() => import("../pages/FulfilmentPayment"));
const AllInventory = React.lazy(() => import("../pages/Inventory"));
const Birthdays = React.lazy(() => import("../pages/Birthdays"));
const Categories = React.lazy(() => import("../pages/Categories"));
const InventoryStatement = React.lazy(() => import("../pages/InventoryStatement"));
const AdminForgotPassword = React.lazy(() => import("../pages/AdminForgotPassword"));
const ResetPassword = React.lazy(() => import("../pages/AdminChangePassword"));
const ChangePassword = React.lazy(() => import("../pages/ChangePassword"));

//
const TestList = React.lazy(() => import("../pages/tests/TestList"));
const TestSolution = React.lazy(() => import("../pages/tests/TestSolution"));
// const AddTestQuestion = React.lazy(() => import("../pages/tests/AddTestQuestion"));
// const QuestionSingle = React.lazy(() => import("../pages/tests/QuestionSingle"));

const NotFound = React.lazy(() => import("../pages/NotFound"));

function AppRouter() {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <Wrapper>
          <Routes>
            <Route element={<AuthRoute />}>
              <Route index element={<AdminLogin />} />
              <Route path="forgot-password" element={<AdminForgotPassword />} />
              <Route path="reset-password/:token" element={<ResetPassword />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="students" element={<StudentList />} />
              <Route path="students/:id" element={<StudentSingle />} />
              <Route path="payments" element={<Payments />} />
              <Route path="requests" element={<RequestList />} />
              <Route path="requests/:id" element={<RequestSingle />} />
              <Route path="fulfilment-summery" element={<FulfilmentSummery />} />
              <Route path="fulfilment/:sku_code" element={<FulfilmentPayment />} />
              <Route path="batches" element={<BatchList />} />
              <Route path="batches/:id" element={<BatchSingle />} />
              <Route path="courses" element={<CourseList />} />

              <Route path="birthdays" element={<Birthdays />} />
              <Route path="profile" element={<MemberProfile />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="announcements" element={<AnnouncementList />} />
              <Route path="attendance" element={<AttendanceList />} />
              <Route path="performance" element={<PerformanceList />} />
              <Route path="questions">
                <Route index element={<QuestionList />} />
                <Route path="create" element={<QuestionCreate />} />
                {/* <Route path=":id" element={<QuestionSingle />} /> */}
              </Route>

              <Route path="tests">
                <Route index element={<TestList />} />
                <Route path="solution" element={<TestSolution />} />
                {/* <Route path=":id" element={<AddTestQuestion />} /> */}
              </Route>

              <Route element={<ProtectedRoute roles={[UserRole.SUPER_ADMIN, UserRole.MANAGER]} />}>
                <Route path="inventory" element={<AllInventory />} />
                <Route path="categories" element={<Categories />} />
                <Route path="inventory-statement" element={<InventoryStatement />} />
                <Route path="activities" element={<ActivityLogs />} />
              </Route>

              <Route element={<ProtectedRoute roles={[UserRole.SUPER_ADMIN]} />}>
                <Route path="members" element={<MemberList />} />
                <Route path="members/:id" element={<MemberSingle />} />
              </Route>
            </Route>
            <Route path="admin" element={<Navigate to="/" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Wrapper>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRouter;
