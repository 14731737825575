import { useLocation, useSearchParams } from "react-router-dom";
import { useAppSelector } from "./redux.hook";
import { useEffect, useRef } from "react";

export const useSearchQuery = (name: string): string => {
  const location = useLocation();
  const [query] = useSearchParams(location.search);

  return query.get(name) || "";
};

export const useUserInfo = () => {
  const user = useAppSelector((state) => state.auth.user);

  return user;
};

export const useSkipInitEffect = (callback: Function, dependency: any[]) => {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;
    if (ref.current) {
      callback();
    }
  }, [...dependency]);
};
// useEffect(() => {
//   singleRef.current = true;
//   if (singleRef.current && _question) {
//     setState({
//       ...state,
//       difficulty_level: { label: _question.difficulty_level, value: _question.difficulty_level },
//       subject: { label: _question.subject?.name, value: _question.subject_id },
//       chapter: { label: _question.chapter?.name, value: _question.chapter_id },
//       question: _question.question,
//       options: _question.options.map((o) => ({ answer: o.answer, correct: o.correct, id: o.id })),
//       solution: _question.solution,
//       tags: _question.tags.map((t) => ({ label: t.name, value: t.id })),
//     });
//   }
// }, [_question]);
