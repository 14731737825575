import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { AuthState, LoginRequest, Member, UpdateMemberDto } from "../../interfaces";
import { toastMessage } from "../../utils/toastError";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/admin",
    credentials: "include",
  }),
  endpoints: (build) => ({
    login: build.mutation<AuthState, LoginRequest>({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
    logout: build.mutation<any, void>({
      query: () => ({
        url: "/logout",
        method: "GET",
      }),
      onQueryStarted() {
        toastMessage("success", "Succesfully Logged Out");
      },
      // invalidatesTags:
    }),
    authenticate: build.query<any, void>({
      query: () => ({
        url: "/authenticate",
        method: "GET",
      }),
    }),
    changePassword: build.mutation<any, { password: string; newPassword: string }>({
      query: (body) => ({
        url: "/change-password",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", baseQueryReturnValue);
      },
    }),
    updateProfile: build.mutation<Member, Partial<UpdateMemberDto>>({
      query: (body) => ({
        url: "/profile",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useAuthenticateQuery,
  useUpdateProfileMutation,
  useChangePasswordMutation,
} = authApi;
