import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { toastMessage } from "../../utils/toastError";
import { File as ContentFile } from "../../interfaces";

export const fileApi = createApi({
  reducerPath: "fileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/file",
    credentials: "include",
  }),
  endpoints: (build) => ({
    uploadFile: build.mutation<{ file: ContentFile; message: string }, File>({
      query: (body) => {
        const formData = new FormData();
        formData.append("file", body);
        return {
          url: "/",
          method: "POST",
          body: formData,
        };
      },
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      // transformResponse(baseQueryReturnValue) {
      //   toastMessage("success", "Successfully uploaded file");
      //   return baseQueryReturnValue;
      // },
    }),
  }),
});

export const { useUploadFileMutation } = fileApi;
