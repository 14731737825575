import React, { ReactNode } from "react";
import Loader from "react-loader-spinner";
import { twMerge } from "tailwind-merge";

interface IButton {
  className?: string;
  text: string | ReactNode;
  size?: string;
  bg?: string;
  textColor?: string;
  loading?: boolean;
  onClick?: Function;
  loaderColor?: string;
  icon?: ReactNode;
  [key: string]: any;
}

export function Button({
  text,
  bg = "",
  size,
  className,
  loading = false,
  textColor = "text-gray-200",
  onClick,
  loaderColor,
  icon,
  ...props
}: IButton) {
  return (
    <button
      onClick={() => {
        if (loading) return;
        if (onClick) onClick();
      }}
      className={twMerge("p-2 rounded-md transition disabled:opacity-50 ", bg, size, textColor, className)}
      disabled={loading}
      {...props}
    >
      {loading ? (
        <div className="flex gap-3 items-center justify-center">
          <Loader type="Oval" height={20} width={20} color={loaderColor} /> {text}
        </div>
      ) : (
        <div className="flex gap-1 items-center justify-center">
          {icon}
          {text}
        </div>
      )}
    </button>
  );
}
