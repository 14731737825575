import React, { useEffect } from "react";
import {
  AiFillExperiment,
  AiOutlineBars,
  AiOutlineDollarCircle,
  AiOutlinePullRequest,
  AiOutlineUsergroupDelete,
} from "react-icons/ai";
import { FaBirthdayCake, FaLayerGroup, FaToolbox, FaUsers } from "react-icons/fa";
import { MdEmojiPeople } from "react-icons/md";
import { TfiAnnouncement, TfiDashboard } from "react-icons/tfi";
import { IoMdGrid } from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";

import { setTitle } from "../redux/app/app.slice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { UserRole } from "../interfaces";
import { BsQuestionCircle } from "react-icons/bs";

export interface ISidebar {
  label: string;
  path: string;
  icon: React.ReactNode;
  exact: boolean;
  access?: string[];
  hidden?: boolean;
}

const hiddenSidebar: ISidebar[] = [
  {
    label: "Change Password",
    path: "/change-password",
    icon: <IoMdGrid />,
    exact: true,
    hidden: true,
  },
  {
    label: "My Profile",
    path: "/profile",
    icon: <IoMdGrid />,
    exact: true,
    hidden: true,
  },
];

const sidebar: ISidebar[] = [
  {
    label: "Dashboard",
    path: "/dashboard",
    icon: <AiOutlineBars />,
    exact: true,
  },
  {
    label: "Batches",
    path: "/batches",
    icon: <FaLayerGroup />,
    exact: true,
  },
  {
    label: "Courses",
    path: "/courses",
    icon: <FaLayerGroup />,
    exact: true,
  },
  {
    label: "Students",
    path: "/students",
    icon: <AiOutlineUsergroupDelete />,
    exact: true,
  },
  {
    label: "Payments",
    path: "/payments",
    icon: <AiOutlineDollarCircle />,
    exact: true,
  },
  {
    label: "Fulfilments",
    path: "/fulfilment-summery",
    icon: <AiOutlineDollarCircle />,
    exact: true,
  },
  {
    label: "Requests",
    path: "/requests",
    icon: <AiOutlinePullRequest />,
    exact: true,
  },
  {
    label: "Birthdays",
    path: "/birthdays",
    icon: <FaBirthdayCake />,
    exact: true,
  },
  {
    label: "Inventory",
    path: "/inventory",
    icon: <FaToolbox />,
    exact: true,
    access: [UserRole.SUPER_ADMIN, UserRole.MANAGER],
  },
  {
    label: "Members",
    path: "/members",
    icon: <FaUsers />,
    exact: true,
    access: [UserRole.SUPER_ADMIN],
  },
  {
    label: "Categories",
    path: "/categories",
    icon: <IoMdGrid />,
    exact: true,
    access: [UserRole.SUPER_ADMIN, UserRole.MANAGER],
  },
  {
    label: "Activity Logs",
    path: "/activities",
    icon: <IoMdGrid />,
    exact: true,
    access: [UserRole.SUPER_ADMIN, UserRole.MANAGER],
  },
  {
    label: "Announcements",
    path: "/announcements",
    icon: <TfiAnnouncement />,
    exact: true,
  },
  {
    label: "Attendance",
    path: "/attendance",
    icon: <MdEmojiPeople />,
    exact: true,
  },
  {
    label: "Performance",
    path: "/performance",
    icon: <TfiDashboard />,
    exact: true,
  },
  {
    label: "Questions",
    path: "/questions",
    icon: <BsQuestionCircle />,
    exact: true,
  },
  {
    label: "Tests",
    path: "/tests",
    icon: <AiFillExperiment />,
    exact: true,
  },
  ...hiddenSidebar,
];

export function SidebarMenu({ onSidebarClick }: { onSidebarClick?: Function }) {
  const dispatch = useAppDispatch();
  const { role } = useAppSelector((state) => state.auth.user);
  const location = useLocation();

  useEffect(() => {
    const page = sidebar.find((s) => location.pathname.includes(s.path));
    if (page) {
      dispatch(setTitle(page?.label));
    }
  }, [location]);

  let navClassName = "flex p-3 w-full justify-between cursor-pointer items-center text-white ";

  return (
    <ul className="m-0 p-0 list-none mt-4 mb-4 ">
      {sidebar.map((opt) => {
        if (opt.hidden) {
          return null;
        }
        if (!opt.access || opt.access?.includes(role)) {
          return (
            <li key={opt.path}>
              <NavLink
                key={opt.path}
                to={opt.path}
                className={({ isActive }) => (isActive ? navClassName + "bg-gray-600 text-gray-100" : navClassName)}
                onClick={() => {
                  if (onSidebarClick) {
                    onSidebarClick();
                  }
                }}
              >
                <div className="flex items-center">
                  {opt.icon}
                  <span className="text-sm  ml-2">{opt.label}</span>
                </div>
              </NavLink>
            </li>
          );
        }
        return null;
      })}
    </ul>
  );
}
