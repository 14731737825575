import { ReactNode } from "react";
import Loader from "react-loader-spinner";
import { twMerge } from "tailwind-merge";

interface ICard {
  children?: ReactNode;
  className?: string;
  onClick?: Function;
  loading?: boolean;
}

export function Card({ className, children, onClick, loading = false }: ICard) {
  return (
    <div
      onClick={() => {
        if (onClick) onClick();
      }}
      className={twMerge("bg-slate-50 border border-slate-300 rounded-lg shadow-xl p-6 ", className)}
    >
      {loading ? <Loader type="Oval" color="#6137f8" width={40} /> : children}
    </div>
  );
}
