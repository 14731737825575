import React, { createContext, useContext, useEffect, useState } from "react";

import { twMerge } from "tailwind-merge";

const DropdownContext = createContext({
  visible: false,
  onClick: () => {},
});

interface IDropdown {
  onConfirm?: () => Promise<any>; // If return boolean then the modal will turn off
  onCancel?: Function;
  onOpen?: Function;
  title?: string;
  description?: any;
  children?: any;
  okText?: string;
  cancelText?: string;
  className?: string;
  okButtonProps?: any;
  cancelButtonProps?: any;
  style?: any;
  visible?: boolean;
}

export function Dropdown({ onOpen, children, className = "" }: IDropdown) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      const dropdown = document.getElementById("dropdown-trigger");
      if (dropdown) {
        const eventPath = e.composedPath();

        if (!eventPath.includes(dropdown)) {
          setVisible(false);
        }
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const onClick = () => {
    setVisible(!visible);
    if (onOpen) onOpen();
  };

  return (
    <DropdownContext.Provider value={{ visible, onClick }}>
      <div id="dropdown" className={twMerge("", className)}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
}

const Trigger = ({ children, className = "" }: IDropdown) => {
  const { onClick } = useContext(DropdownContext);
  return (
    <div
      id="dropdown-trigger"
      onClick={onClick}
      className={twMerge("flex items-center gap-2 cursor-pointer ", className)}
    >
      {children}
    </div>
  );
};

const Body = ({ children, className = "" }: IDropdown) => {
  const { visible } = useContext(DropdownContext);
  return visible ? (
    <div
      id="dropdown-body"
      className={twMerge(
        "absolute right-3 top-20 p-3 max-w-[300] min-w-[250px] bg-white border border-slate-100 border-t-2 shadow-xl z-50 rounded-sm ",
        className
      )}
    >
      {children}
    </div>
  ) : (
    <div></div>
  );
};

Dropdown.Trigger = Trigger;
Dropdown.Body = Body;
