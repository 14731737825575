import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { toastMessage } from "../../utils/toastError";
import { ContentsResponse, CreateContentDto, GetContentsDto, UpdateContentDto } from "../../interfaces";

export const contentApi = createApi({
  reducerPath: "contentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/content",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getContents: build.query<ContentsResponse, Partial<GetContentsDto>>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
    createContent: build.mutation<any, CreateContentDto>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully created!");
        return baseQueryReturnValue;
      },
    }),

    getVideoInfo: build.mutation<{ success: boolean; title: string; thumbnail: string }, { url: string }>({
      query: (body) => ({
        url: "/video-info",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
    updateContent: build.mutation<any, UpdateContentDto>({
      query: ({ id, ...body }) => ({
        url: "/" + id,
        method: "PUT",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully updated!");
        return baseQueryReturnValue;
      },
    }),
    deleteContent: build.mutation<any, { id: string; hard: boolean }>({
      query: ({ id, hard }) => ({
        url: "/" + id,
        method: "DELETE",
        body: { hard },
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully deleted!");
        return baseQueryReturnValue;
      },
    }),
  }),
});

export const {
  useCreateContentMutation,
  useGetContentsQuery,
  useUpdateContentMutation,
  useGetVideoInfoMutation,
  useDeleteContentMutation,
} = contentApi;
