import Logo from "../assets/logo.webp";

import { SidebarMenu } from "./SidebarMenu";

interface IMobileSidebar {
  open: boolean;
  setOpen?: Function;
}

export function MobileSidebar({ open, setOpen }: IMobileSidebar) {
  return (
    <div
      className={
        "flex min-h-screen fixed top-0 sm:hidden transform transition-all z-50 " +
        (!open ? "-translate-x-64" : "")
      }
    >
      {/* Sidebar starts */}
      {/* Remove class [ hidden ] and replace [ sm:flex ] with [ flex ] */}
      <div className="w-64 absolute sm:relative  shadow flex-col justify-between bg-slate-900 ">
        <div className="w-full h-screen overflow-y-auto pb-5">
          <div className="h-16 w-full flex items-center justify-center">
            <img className="w-16" src={Logo} alt="logo" />
          </div>
          <SidebarMenu
            onSidebarClick={() => {
              if (setOpen) {
                setOpen(false);
              }
            }}
          />
        </div>
      </div>
      {/* Sidebar ends */}
    </div>
  );
}
