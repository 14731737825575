import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";

import { Button } from "./Button";
import { twMerge } from "tailwind-merge";

export interface IConfirmDrawer {
  onConfirm?: () => any; // If return boolean then the modal will turn off
  onCancel?: Function;
  onOpen?: Function;
  title?: string;
  description?: any;
  children?: any;
  okText?: string;
  cancelText?: string;
  className?: string;
  okButtonProps?: any;
  cancelButtonProps?: any;
  style?: any;
  visible?: boolean;
  disabled?: boolean;
  width?: string | number;
  showActions?: boolean;
  showHeader?: boolean;
}

export function ConfirmDrawer({
  onConfirm,
  onCancel,
  onOpen,
  title = "Confirmation!",
  description = "Do you really want to confirm?",
  children,
  okText,
  cancelText,
  okButtonProps,
  cancelButtonProps,
  visible: _visible,
  disabled,
  width,
  showActions = true,
  showHeader = true,
}: IConfirmDrawer) {
  const [visible, setVisible] = useState<boolean | undefined>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setVisible(_visible);
  }, [_visible]);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      if (onConfirm) {
        await onConfirm();
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onClick = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    if (onCancel) onCancel();
  };

  useEffect(() => {
    if (visible) {
      if (onOpen) onOpen();
    }
  }, [visible]);

  return disabled ? (
    children
  ) : (
    <>
      {visible && (
        <div className="fixed w-screen h-screen inset-0 flex justify-center items-center z-50">
          <div className="fixed w-screen h-screen inset-0 bg-black opacity-50"></div>
          <div
            className={"w-full h-screen flex justify-end flex-row "}
            // className={`lg:min-w-[400px] w-full relative z-20 h-full  ${className} `}
            // style={{ ...style }}
          >
            <div
              className={twMerge("w-full md:w-60/100 lg:w-40/100 flex flex-col p-0 bg-white relative overflow-y-auto")}
              style={{ width }}
            >
              {showHeader && (
                <React.Fragment>
                  <div className="cursor-pointer absolute top-1 right-1" onClick={onClose}>
                    <IoIosCloseCircleOutline size={25} />
                  </div>
                  <div className="modal-head text-lg text-center p-2 bg-gray-50">{title}</div>
                </React.Fragment>
              )}
              <div className="modal-sub p-5 flex-1 overflow-y-auto overflow-x-hidden">
                {typeof description === "function" ? description({ onCancel, onConfirm }) : description}
              </div>
              {showActions && (
                <div className="flex justify-end gap-2 p-2 bg-gray-50">
                  <Button
                    text={cancelText || "Cancel"}
                    className="hover:bg-red-600 disabled:bg-gray-400 disabled:hover:bg-gray-400 px-5"
                    bg="bg-red-500"
                    textColor="text-white"
                    onClick={onClose}
                    disabled={loading}
                    {...cancelButtonProps}
                  />
                  <Button
                    text={okText || "Confirm"}
                    className="hover:bg-green-600 disabled:bg-gray-400 disabled:hover:bg-gray-400 px-5"
                    bg="bg-green-500"
                    textColor="text-white"
                    loading={loading}
                    onClick={handleConfirm}
                    {...okButtonProps}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {children && React.cloneElement(children, { onClick: onClick })}
    </>
  );
}
