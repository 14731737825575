import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { GetActivityDto, GetActivityLogResponse } from "../../interfaces";

export const activityApi = createApi({
  reducerPath: "activityApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/activity",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getActivities: build.query<GetActivityLogResponse, Partial<GetActivityDto>>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useGetActivitiesQuery, useLazyGetActivitiesQuery } = activityApi;
