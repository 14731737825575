import React, { ChangeEvent } from "react";
import { FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp } from "react-icons/fa";

import { Select } from "./Select";

interface ITable {
  id?: string;
  tableId?: string;
  headers: (string | { name: any; value: string })[];
  keys: string[];
  data: any[];
  sortColumn?: string;
  orderby?: string;
  onRowClick?: Function;
  onHeadingClick?: Function;
  skip?: number;
  take?: number;
  total?: number;
  onLeft?: Function;
  onRight?: Function;
  className?: string;
  onPageSizeChange?: Function;
}

export function Table({
  id,
  tableId,
  data,
  headers,
  keys,
  sortColumn,
  orderby,
  onRowClick,
  onHeadingClick,
  skip,
  take,
  total,
  onLeft,
  onRight,
  className,
  onPageSizeChange,
  ...props
}: ITable) {
  return (
    <div {...props} id={id} className={"flex flex-col w-full max-w-full overflow-hidden bg-white " + className}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg w-full">
            <table className="w-full divide-y divide-gray-200" id={tableId}>
              <thead className="bg-gray-50">
                <tr>
                  {headers.map((e, i) => {
                    return (
                      <th
                        key={i}
                        scope="col"
                        className={
                          "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider " +
                          (typeof e === "string" ? "" : "cursor-pointer")
                        }
                        onClick={() => {
                          if (onHeadingClick) onHeadingClick(e);
                        }}
                      >
                        {typeof e === "string" ? (
                          e
                        ) : (
                          <div className="flex items-center">
                            <p>{e.name}</p>
                            {sortColumn === e.value ? (
                              orderby === "ASC" ? (
                                <FaChevronDown className="ml-2" />
                              ) : orderby === "DESC" ? (
                                <FaChevronUp className="ml-2" />
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                        {}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((e, i) => (
                  <tr
                    key={i}
                    className={(i % 2 !== 0 ? "bg-white " : "bg-gray-200 ") + (onRowClick ? "cursor-pointer" : "")}
                  >
                    {keys.map((f, i) => {
                      if (typeof e[f] !== "object")
                        return (
                          <td
                            key={i}
                            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                            onClick={(event) => {
                              if (event.view.document.getSelection()?.type === "Range") return;
                              else if (onRowClick) onRowClick(e);
                            }}
                          >
                            {e[f]}
                          </td>
                        );
                      else if (e[f].icon) {
                        return (
                          <td
                            key={i}
                            className="px-6 py-4 whitespace-nowrap font-medium text-gray-900 flex justify-start items-center cursor-pointer"
                            onClick={() => e[f].onClick && e[f].onClick()}
                          >
                            {e[f].icon}
                          </td>
                        );
                      } else {
                        return (
                          <td key={i} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {e[f]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            {skip !== undefined && total !== undefined ? (
              <div className="flex justify-between mt-5 px-2 py-2">
                {onPageSizeChange ? (
                  <Select
                    id="limit"
                    label="Number of Rows"
                    options={[
                      { name: "All", value: "" },
                      { name: "20", value: "20" },
                      { name: "50", value: "50" },
                      { name: "100", value: "100" },
                    ]}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      console.log(e.target.value);
                      if (onPageSizeChange) {
                        onPageSizeChange(e.target.value ? parseInt(e.target.value) : undefined);
                      }
                    }}
                    value={`${take}`}
                    className="flex flex-row items-center gap-3"
                  />
                ) : (
                  <span></span>
                )}
                {take !== undefined ? (
                  <div className="flex items-center justify-center">
                    <FaChevronLeft
                      className="text-md mr-2 cursor-pointer"
                      onClick={() => {
                        if (onLeft) onLeft();
                      }}
                    />
                    <p className="text-lg">
                      {skip + 1} - {Math.min(total, skip + take)} of {total}
                    </p>
                    <FaChevronRight
                      className="text-md ml-2 cursor-pointer"
                      onClick={() => {
                        if (onRight) onRight();
                      }}
                    />
                  </div>
                ) : (
                  <span></span>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
