import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { toastMessage } from "../../utils/toastError";
import { API_ENDPOINT } from "../../config";
import {
  CreateAttendanceClassDto,
  GetBatchClassDto,
  UpdateAttendanceClassDto,
} from "../../interfaces/dto/attendance.dto";
import { BatchClassesResponse, BatchClassesResponseStudent } from "../../interfaces/attendance.interface";

export const attendanceApi = createApi({
  reducerPath: "attendanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/attendance",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getAttendances: build.query<BatchClassesResponse, GetBatchClassDto>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
    }),
    getStudentAttendances: build.query<BatchClassesResponseStudent, GetBatchClassDto>({
      query: (params) => ({
        url: "/student",
        method: "GET",
        params,
      }),
    }),

    getAttendanceReport: build.query<any[], GetBatchClassDto>({
      query: (params) => ({
        url: "/report",
        method: "GET",
        params,
      }),
    }),

    createAttendance: build.mutation<any, CreateAttendanceClassDto>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully created");
        return baseQueryReturnValue;
      },
    }),

    updateAttendance: build.mutation<any, UpdateAttendanceClassDto>({
      query: ({ id, ...body }) => ({
        url: "/" + id,
        method: "PUT",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully updated");
        return baseQueryReturnValue;
      },
    }),
    deleteAttendance: build.mutation<any, string>({
      query: (id) => ({
        url: "/" + id,
        method: "DELETE",
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully deleted");
        return baseQueryReturnValue;
      },
    }),
  }),
});

export const {
  useCreateAttendanceMutation,
  useUpdateAttendanceMutation,
  useGetAttendancesQuery,
  useGetStudentAttendancesQuery,
  useLazyGetAttendanceReportQuery,
  useDeleteAttendanceMutation,
} = attendanceApi;
