import React, { ChangeEventHandler } from "react";
import { twMerge } from "tailwind-merge";

interface ISwitch {
  checked: boolean;
  onChange?: ChangeEventHandler;
  className?: string;
  label?: any;
  id?: string;
}

export function Switch({ checked = false, onChange = () => {}, className, label, id }: ISwitch) {
  return (
    <label className={twMerge("flex flex-col items-start ", className)}>
      {label && <span className="text-gray-500  text-sm flex-shrink-0">{label}</span>}
      <div
        // htmlFor="default-toggle"
        className="inline-flex relative items-center cursor-pointer"
      >
        <input type="checkbox" value="" id={id} className="sr-only peer" checked={checked} onChange={onChange} />
        <div
          className={`
          w-11 
          h-6
          bg-gray-400 
          peer-focus:outline-none 
          rounded-full peer 
          peer-checked:after:translate-x-full 
          after:content-[''] 
          after:absolute after:top-[2px] after:left-[2px] 
          after:bg-white 
          after:rounded-full 
          after:h-5 
          after:w-5 
          after:transition-all 
          peer-checked:bg-blue-600
        `}
        ></div>
      </div>
    </label>
  );
}
