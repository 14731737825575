import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";

import { ReduxProvider } from "./redux/provider";
import { axiosMiddleware } from "./utils/axiosInstance";
import reportWebVitals from "./reportWebVitals";
import AppRouter from "./routes";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "cropperjs/dist/cropper.css";
import "./styles/style.css";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      math: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

axiosMiddleware();

const isMaintenace = false;

const Maintenance = () => {
  return (
    <div className="text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <h1 className="text-3xl text-red-400 font-bold">Under construction</h1>
      <p className="text-gray-400">Please be patience. We are working on our system.</p>
    </div>
  );
};

const Main: React.FC = () => (
  <React.Fragment>
    <ReduxProvider>
      <Toaster />
      <AppRouter />
    </ReduxProvider>
  </React.Fragment>
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(isMaintenace ? <Maintenance /> : <Main />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
