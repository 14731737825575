import { Action, AnyAction, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { appReducer } from "./app/app.slice";
import { batchApi } from "./batch/batch.api";
import { categoryApi } from "./category/category.api";
import { dashboardApi } from "./dashboard/dashboard.api";
import { requestApi } from "./request/request.api";
import { authReducer } from "./auth/auth.slice";
import { memberApi } from "./member/member.api";
import { authApi } from "./auth/auth.api";
import { activityApi } from "./activity/activity.api";
import { studentApi } from "./student/student.api";
import { fileApi } from "./app/file.api";
import { announcementApi } from "./announcement/annoucement.api";
import { paymentApi } from "./payment/payment.api";
import { contentApi } from "./content/content.api";
import { attendanceApi } from "./attendance/attendance.api";
import { performanceApi } from "./performance/performance.api";
import { fulfilmentApi } from "./fulfilment/fulfilment.api";
import { questionApi } from "./question/question.api";
import { testApi } from "./test/test.api";

const persistConfig = {
  key: "CHESTA_AUTH",
  storage,
};

const rootReducer = combineReducers({
  app: appReducer,
  auth: persistReducer(persistConfig, authReducer),
  [requestApi.reducerPath]: requestApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [batchApi.reducerPath]: batchApi.reducer,
  [studentApi.reducerPath]: studentApi.reducer,
  [memberApi.reducerPath]: memberApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [activityApi.reducerPath]: activityApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [announcementApi.reducerPath]: announcementApi.reducer,
  [testApi.reducerPath]: testApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [attendanceApi.reducerPath]: attendanceApi.reducer,
  [performanceApi.reducerPath]: performanceApi.reducer,
  [fulfilmentApi.reducerPath]: fulfilmentApi.reducer,
  [questionApi.reducerPath]: questionApi.reducer,
});

const mainReducer = (rootState: RootReducerType | undefined, action: AnyAction) => {
  let state = rootState;
  if (action.type === "authApi/executeMutation/pending") {
    if (action.meta.arg.endpointName === "logout") {
      state = undefined;
      storage.removeItem(`persist:${persistConfig.key}`);
    }
  }

  return rootReducer(state, action);
};

const store = configureStore({
  reducer: mainReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(requestApi.middleware)
      .concat(categoryApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(batchApi.middleware)
      .concat(memberApi.middleware)
      .concat(authApi.middleware)
      .concat(activityApi.middleware)
      .concat(studentApi.middleware)
      .concat(fileApi.middleware)
      .concat(announcementApi.middleware)
      .concat(testApi.middleware)
      .concat(paymentApi.middleware)
      .concat(contentApi.middleware)
      .concat(attendanceApi.middleware)
      .concat(performanceApi.middleware)
      .concat(fulfilmentApi.middleware)
      .concat(questionApi.middleware),
});

export type RootReducerType = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const persistor = persistStore(store);
export default store;
