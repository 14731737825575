import { useEffect, useState } from "react";

import { Category, IGetCategory } from "../interfaces";
import { getCategories } from "../redux/category/category.actions";

export const useCategories = (params?: IGetCategory) => {
  const [entries, setEntries] = useState<Category[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    refetch();
  }, []);

  function refetch() {
    getCategories(params)
      .then((data) => {
        setEntries(data.entries);
        setTotal(data.total);
      })
      .finally(() => setLoading(false));
  }

  return { entries, loading, total, refetch };
};
