import toast from 'react-hot-toast';
import axios, { AxiosError } from 'axios';

import { CreateCategoryDto, IGetCategory } from '../../interfaces';
import { toastError } from '../../utils/toastError';

export const getCategories = async (params?: Partial<IGetCategory>) => {
  try {
    const { data } = await axios.get(`/category`, {
      params,
      withCredentials: true,
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(null);
  }
};

export const createCategory = async (payload: CreateCategoryDto) => {
  try {
    const { data } = await axios.post(`/category`, { ...payload });
    toast.success('Category created!');
    return Promise.resolve(data);
  } catch (error) {
    toastError(error as Error | AxiosError);
    return Promise.reject(error);
  }
};
export const deleteCategory = async (id: string) => {
  try {
    await axios.delete(`/category/${id}`);

    toast.success('Category deleted!');
    return Promise.resolve(true);
  } catch (error) {
    toastError(error as Error | AxiosError);
    return Promise.reject(error);
  }
};

export const updateCategory = async (id: string, payload: Partial<CreateCategoryDto>) => {
  try {
    const { data } = await axios.put(`/category/${id}`, payload);

    toast.success('Category updated!');
    return Promise.resolve(data);
  } catch (error) {
    toastError(error as Error | AxiosError);
    return Promise.reject(error);
  }
};
