import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { Category, CategoryResponse, CategoryTypes, CreateCategoryDto, UpdateCategoryDto } from "../../interfaces";
import { GetCategoriesDto } from "../../interfaces/dto/default.dto";
import { toastMessage } from "../../utils/toastError";

export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/category",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getCategories: build.query<CategoryResponse, GetCategoriesDto>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
    }),
    assignCategory: build.mutation<Category, { id: string; assignedId: string }>({
      query: (params) => ({
        url: "/assign",
        method: "PUT",
        params,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Category) {
        toastMessage("success", "Successfully deleted");
        return baseQueryReturnValue;
      },
    }),
    createCategory: build.mutation<Category, CreateCategoryDto>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Category) {
        toastMessage("success", "Successfully deleted");
        return baseQueryReturnValue;
      },
    }),
    deleteCategory: build.mutation<Category, string>({
      query: (id) => ({
        url: "/" + id,
        method: "DELETE",
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Category) {
        toastMessage("success", "Successfully deleted");
        return baseQueryReturnValue;
      },
    }),
    updateCategory: build.mutation<Category, UpdateCategoryDto>({
      query: ({ id, ...body }) => ({
        url: "/" + id,
        method: "PUT",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Category) {
        toastMessage("success", "Successfully deleted");
        return baseQueryReturnValue;
      },
    }),
    getCategoryTypes: build.query<CategoryTypes[], void>({
      query: () => ({
        url: "/types",
        method: "GET",
      }),
    }),
    createCategoryType: build.mutation<CategoryTypes, { name: string; key: string }>({
      query: (payload) => ({
        url: "/types",
        method: "POST",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
    }),
    updateCategoryType: build.mutation<CategoryTypes, { id: string; name: string; key: string }>({
      query: (payload) => ({
        url: "/types",
        method: "PUT",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
    }),
    deleteCategoryType: build.mutation<any, string>({
      query: (id) => ({
        url: "/types",
        method: "DELETE",
        body: { id },
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully deleted");
        return baseQueryReturnValue;
      },
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetCategoryTypesQuery,
  useCreateCategoryTypeMutation,
  useDeleteCategoryTypeMutation,
  useUpdateCategoryTypeMutation,
  useAssignCategoryMutation,
  useDeleteCategoryMutation,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
} = categoryApi;
