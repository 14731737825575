import { Navigate, Outlet } from "react-router-dom";

import { useAppSelector } from "../hooks";
import { RootState } from "../redux/store";
import { UserRole } from "../interfaces";
import { useAuthenticateQuery } from "../redux/auth/auth.api";

export const PrivateRoute = () => {
  useAuthenticateQuery();
  const isAuthenticated = useAppSelector((state: RootState) => !!state.auth.loggedIn);

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

interface IProtectedRoute {
  roles: UserRole[];
}

export const ProtectedRoute = ({ roles }: IProtectedRoute) => {
  const { role } = useAppSelector((state) => state.auth.user);

  return roles.includes(role as UserRole) ? <Outlet /> : <Navigate to="/" />;
};
