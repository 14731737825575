import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { FulfilmentList } from "../../interfaces";
import { PaginationDto } from "../../interfaces/dto/default.dto";
import { toastMessage } from "../../utils/toastError";

export const fulfilmentApi = createApi({
  reducerPath: "fulfilmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/admin/fulfilment",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getFulfilments: build.query<{ entries: FulfilmentList[]; total: number }, PaginationDto>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
    }),
    generateFulfilmentOrder: build.mutation<any, string>({
      query: (id) => ({
        url: "/generate-order/" + id,
        method: "POST",
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", baseQueryReturnValue);
      },
    }),
  }),
});

export const { useGetFulfilmentsQuery, useLazyGetFulfilmentsQuery, useGenerateFulfilmentOrderMutation } = fulfilmentApi;
