import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import {
  Test,
  CreateTestDto,
  GetTestDto,
  GetTestResponse,
  UpdateTestDto,
  SectionResponse,
  Section,
  StudentTest,
} from "../../interfaces";
import { toastMessage } from "../../utils/toastError";

interface GetStudentTestDto {
  id?: string;
  type: "test" | "result" | "solution";
  test_id?: string;
  batch_id?: string;
  student_id?: string;
}

export const testApi = createApi({
  reducerPath: "testApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/test",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getTests: build.query<GetTestResponse, Partial<GetTestDto>>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
    }),

    getStudentTest: build.query<StudentTest, GetStudentTestDto>({
      query: (params) => ({
        url: "/student/student-test",
        method: "GET",
        params,
      }),
    }),

    createTest: build.mutation<Test, CreateTestDto>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
    updateTest: build.mutation<any, Partial<UpdateTestDto>>({
      query: ({ id, ...body }) => ({
        url: "/" + id,
        method: "PUT",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse() {
        toastMessage("success", "Updated successfully");
      },
    }),
    updateTestStatus: build.mutation<any, { id: string; status: string }>({
      query: ({ id, ...body }) => ({
        url: "/status/" + id,
        method: "PUT",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse() {
        toastMessage("success", "Updated successfully");
      },
    }),
    getTest: build.query<Test, string>({
      query: (id) => ({
        url: "/" + id,
        method: "GET",
      }),
    }),

    deleteTest: build.mutation<any, any>({
      query: (id) => ({
        url: "/" + id,
        method: "DELETE",
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully Deleted");
        return baseQueryReturnValue;
      },
    }),
    createTestQuestion: build.mutation<any, { questionIds: string[]; sectionId: string }>({
      query: (body) => ({
        url: "/section/question",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully Added");
        return baseQueryReturnValue;
      },
    }),
    getSections: build.query<SectionResponse, any>({
      query: (params) => ({
        url: "/section",
        method: "GET",
        params,
      }),
    }),
    createSection: build.mutation<Section, { name: string; test_id: string }>({
      query: (payload) => ({
        url: "/section",
        method: "POST",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Section) {
        toastMessage("success", "Successfully created");
        return baseQueryReturnValue;
      },
    }),
    updateSection: build.mutation<any, { id: string; name: string }>({
      query: (payload) => ({
        url: "/section/" + payload.id,
        method: "PUT",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully updated");
        return baseQueryReturnValue;
      },
    }),
    deleteSection: build.mutation<any, string | number>({
      query: (id) => ({
        url: "/section/" + id,
        method: "DELETE",
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully updated");
        return baseQueryReturnValue;
      },
    }),
  }),
});

export const {
  useCreateSectionMutation,
  useUpdateSectionMutation,
  useGetTestsQuery,
  useGetSectionsQuery,
  useCreateTestQuestionMutation,
  useGetTestQuery,
  useLazyGetTestQuery,
  useCreateTestMutation,
  useUpdateTestMutation,
  useUpdateTestStatusMutation,
  useDeleteTestMutation,
  useDeleteSectionMutation,
  useGetStudentTestQuery,
} = testApi;
