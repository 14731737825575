import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { Student, StudentBatch, UpdateStudentBatch } from "../../interfaces";
import { GetListDto } from "../../interfaces/dto/default.dto";
import { toastMessage } from "../../utils/toastError";

export const studentApi = createApi({
  reducerPath: "studentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/admin/student",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getStudents: build.query<{ students: Student[]; total: number }, Partial<GetListDto>>({
      query: (params) => ({
        url: "/all",
        method: "GET",
        params,
      }),
    }),
    getStudent: build.query<Student, string>({
      query: (id) => ({
        url: "/" + id,
        method: "GET",
      }),
    }),
    updateStudent: build.mutation<any, Partial<Student>>({
      query: ({ id, ...body }) => ({
        url: "/" + id,
        method: "PUT",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse() {
        toastMessage("success", "Succesfully updated");
      },
    }),
    updateStudentBatch: build.mutation<StudentBatch, UpdateStudentBatch>({
      query: (body) => ({
        url: "/batch",
        method: "PUT",
        body,
      }),
    }),
    deleteStudentBatch: build.mutation<any, string>({
      query: (id) => ({
        url: "/batch/" + id,
        method: "DELETE",
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(response) {
        toastMessage("success", "Succesfully deleted");
        return response;
      },
    }),
    addStudentBatch: build.mutation<StudentBatch, UpdateStudentBatch>({
      query: (body) => ({
        url: "/batch",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetStudentQuery,
  useGetStudentsQuery,
  useLazyGetStudentsQuery,
  useUpdateStudentBatchMutation,
  useDeleteStudentBatchMutation,
  useAddStudentBatchMutation,
  useUpdateStudentMutation,
} = studentApi;
