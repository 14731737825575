import React, { useState } from "react";
import { AiOutlinePoweroff } from "react-icons/ai";
import { FaAngleDown, FaBars } from "react-icons/fa";
import Loader from "react-loader-spinner";

import { Button, MobileSidebar, SideBar } from "./";
import { Dropdown } from "./Dropdown";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { useLogoutMutation } from "../redux/auth/auth.api";
import Image from "./Image";

interface IPageWrapper {
  loading?: boolean;
  children: any;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  containerClassName?: string;
  header?: React.ReactNode;
  visible?: boolean;
}

export function PageWrapper({ loading = false, title, subtitle, visible = false, ...props }: IPageWrapper) {
  const navigate = useNavigate();
  const { name, profile_picture } = useAppSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);

  const [logoutUser] = useLogoutMutation();

  if (!visible) {
    return props.children;
  }

  return (
    <div className="flex w-full">
      <SideBar />
      <MobileSidebar open={open} setOpen={setOpen} />
      {loading ? (
        <div className="absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%]">
          <Loader type="TailSpin" color="#555555" height={50} width={50} />
        </div>
      ) : (
        ""
      )}

      <div id="admin-dashboard-content" className="w-full h-screen flex flex-col  ">
        {/* Header Section Start */}
        <div className="flex justify-between items-center p-4 border-b shadow-xl bg-white">
          <div>
            {title && <p className="text-gray-700 text-2xl">{title}</p>}
            {subtitle && <p className="text-gray-500 text-lg">{subtitle}</p>}
          </div>
          <div className="flex items-center">
            <Dropdown>
              <Dropdown.Trigger className="text-cyan-600 gap-3">
                <div className="sm:flex items-center gap-2 hidden">
                  <FaAngleDown />
                  <span>Hi, {name}</span>
                </div>
                <div className="w-12 h-12 rounded-full overflow-hidden">
                  <Image src={profile_picture?.url} alt="user-profile" className="w-full" />
                </div>
              </Dropdown.Trigger>
              <Dropdown.Body>
                <div className="flex flex-col gap-2 text-slate-500">
                  <Link to="/profile">Profile</Link>
                  <hr />
                  <Link to="/change-password">Change Password</Link>
                </div>

                <Button
                  className="w-full mt-4 p-2 px-4 rounded-md hover:bg-red-500 hover:text-white transition cursor-pointer flex justify-center items-center border border-red-300 text-red-300"
                  onClick={() => {
                    logoutUser();
                    navigate("/");
                  }}
                  text={
                    <>
                      <AiOutlinePoweroff className="text-lg" />
                      <p className="ml-2">Logout</p>
                    </>
                  }
                />
              </Dropdown.Body>
            </Dropdown>
            <FaBars
              className="text-xl ml-2 sm:hidden"
              onClick={() => {
                setOpen(!open);
              }}
            />
          </div>
        </div>
        {/* Header Section End */}

        {/* Children Component Start */}
        <div className=" p-4 flex-1 overflow-y-auto ">{props.children}</div>
        {/* Children Component End */}
      </div>
    </div>
  );
}
