import React, { MouseEvent } from "react";
import { twMerge } from "tailwind-merge";

export type StatusType = "warning" | "error" | "success" | "info" | "orange";

interface ITag {
  type?: StatusType;
  rounded?: boolean;
  selected?: boolean;
  children?: React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  onClick?: (event?: MouseEvent) => void;
  className?: string;
}

export const StatusTag = ({ type, children, onClick, className: _className = "", selected = false }: ITag) => {
  let className = "border text-sm px-5 py-1 rounded-full ";
  if (type === "warning") {
    className += ` ${
      selected ? "bg-yellow-500 border-yellow-500 text-white" : "border-yellow-200 bg-yellow-50 text-yellow-600"
    } `;
  } else if (type === "error") {
    className += ` ${selected ? "bg-red-500 border-red-500 text-white" : "border-red-200 bg-red-50 text-red-600"} `;
  } else if (type === "success") {
    className += ` ${
      selected ? "bg-green-500 border-green-500 text-white" : "border-green-200 bg-green-50 text-green-600"
    } `;
  } else if (type === "info") {
    className += ` ${
      selected ? "bg-cyan-500 border-cyan-500 text-white" : "border-cyan-200 bg-cyan-50 text-cyan-600"
    } `;
  } else if (type === "orange") {
    className += ` ${
      selected ? "bg-orange-500 border-orange-500 text-white" : "border-orange-200 bg-orange-50 text-orange-600"
    } `;
  } else {
    className += ` ${
      selected ? "bg-gray-500 border-gray-500 text-white" : "border-gray-200 bg-gray-50 text-gray-600"
    } `;
  }

  return (
    <span
      className={twMerge(className, _className)}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
    >
      {children}
    </span>
  );
};
