import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  loading: false,
  visible: false,
};

export const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setVisible: (state, action) => {
      state.visible = action.payload;
    },
  },
});

export const { setTitle, setVisible, setLoading } = slice.actions;

export const appReducer = slice.reducer;
