import { Navigate, Outlet } from "react-router-dom";

import { useAppSelector } from "../hooks";
import { RootState } from "../redux/store";

export const AuthRoute = () => {
  const isAuthenticated = useAppSelector((state: RootState) => !!state.auth.loggedIn);

  return !isAuthenticated ? <Outlet /> : <Navigate to="/dashboard" />;
};
