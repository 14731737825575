import React from "react";
interface ICheckbox {
  label?: React.ReactNode;
  onChange?: Function;
  className?: string;
  id?: string;
}

export function Checkbox({ label, onChange, className, id = "default" }: ICheckbox) {
  return (
    <div className={"flex items-center " + className}>
      <input
        id={id}
        type="checkbox"
        onChange={(e) => {
          if (onChange) onChange(e);
        }}
      />
      <label htmlFor={id} className="text-sm text-dark-blue ml-2">
        {label}
      </label>
    </div>
  );
}
