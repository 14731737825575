import React from "react";

import Logo from "../assets/logo.webp";

import { SidebarMenu } from "./SidebarMenu";

export function SideBar() {
  return (
    <div className="flex flex-no-wrap min-h-screen ">
      {/* Sidebar starts */}
      {/* Remove class [ hidden ] and replace [ sm:flex ] with [ flex ] */}
      <div className="w-48 absolute sm:relative bg-slate-900 shadow md:h-full flex-col justify-between hidden sm:flex ">
        <div className="w-full h-screen overflow-y-auto pb-5">
          <div className="h-16 w-full flex items-center justify-center">
            <img className="w-16" src={Logo} alt="logo" />
          </div>
          <SidebarMenu />
        </div>
      </div>
      {/* Sidebar ends */}
    </div>
  );
}
