import { createSlice } from "@reduxjs/toolkit";

import { AuthState } from "../../interfaces";
import { authApi } from "./auth.api";

const initialState: AuthState = {
  loggedIn: false,
  token: "",
  user: {
    id: "",
    name: "",
    email: "",
    role: "",
    active: true,
    type: "",
  },
  permission: {},
  assigned_batches: [],
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.user = payload.user || {};
      state.permission = payload.permission || {};
      state.token = payload.token;
      state.loggedIn = true;
      state.assigned_batches = payload.assigned_batches || [];
    });

    builder.addMatcher(authApi.endpoints.logout.matchPending, () => {
      return initialState;
    });
    builder.addMatcher(authApi.endpoints.authenticate.matchRejected, (act, { payload }) => {
      if (payload?.status === 401) {
        return initialState;
      }
    });
    builder.addMatcher(authApi.endpoints.updateProfile.matchFulfilled, (state, { payload }) => {
      Object.assign(state.user, payload);
    });
    builder.addMatcher(authApi.endpoints.authenticate.matchFulfilled, (state, { payload }) => {
      state.user = payload.user || {};
      state.permission = payload.permission || {};
    });
  },
});

export const { setToken } = slice.actions;

export const authReducer = slice.reducer;
