import React, { useRef } from "react";
import { twMerge } from "tailwind-merge";

type Size = "sm" | "lg";

interface IProps {
  label?: React.ReactNode;
  onChange?: Function;
  className?: string;
  id?: string;
  name?: string;
  checked?: boolean;
  size?: Size;
}

export function Radio({ label, onChange, className = "", id = "", name = "", checked, size = "sm" }: IProps) {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <div className={"flex items-center group " + className}>
      <input
        id={id}
        type="radio"
        onChange={(e) => {
          if (onChange) onChange(e);
        }}
        name={name}
        checked={checked}
        className="invisible peer hidden"
        ref={ref}
      />
      <span
        className={twMerge(
          size === "sm" ? "h-4 after:h-2" : "h-6 after:h-3",
          "cursor-pointer relative flex items-center justify-center aspect-square border rounded-full border-gray-400",
          "after:absolute after:aspect-square after:bg-green-500 after:rounded-full after:invisible",
          "peer-checked:after:visible peer-checked:border-green-500"
        )}
        onClick={() => {
          ref.current?.click();
        }}
      ></span>
      {label && (
        <label htmlFor={id} className="text-sm text-dark-blue ml-2">
          {label}
        </label>
      )}
    </div>
  );
}
