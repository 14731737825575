import React from 'react';

interface ISelect {
  id: string;
  className?: string;
  label: string;
  options: string[];
  value: string;
  onChange: Function;
}

export function SearchSelect({ id, className = '', label, options, value, onChange }: ISelect) {
  return (
    <div className={className}>
      <label className="text-gray-500 text-sm" htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        className="w-full p-2 rounded-md"
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        list={'brow' + id}
      />
      <datalist id={'brow' + id}>
        {options.map((e, i) => {
          return (
            <option key={i} value={e}>
              {e}
            </option>
          );
        })}
      </datalist>
    </div>
  );
}
