import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { CreateMemberDto, GetMembersDto, GetMembersResponse, Member, UpdateMemberDto } from "../../interfaces";
import { toastMessage } from "../../utils/toastError";

export const memberApi = createApi({
  reducerPath: "memberApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/admin/member",
    credentials: "include",
  }),
  endpoints: (build) => ({
    createMember: build.mutation<any, CreateMemberDto>({
      query: (payload) => ({
        url: "/",
        method: "POST",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", baseQueryReturnValue);
      },
    }),
    updateMember: build.mutation<any, UpdateMemberDto>({
      query: ({ id, ...payload }) => ({
        url: "/" + id,
        method: "PUT",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse() {
        toastMessage("success", "Succesfully updated");
      },
    }),
    deleteMember: build.mutation({
      query: ({ id }) => ({
        url: "/" + id,
        method: "DELETE",
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse() {
        toastMessage("success", "Succesfully deleted");
      },
    }),
    getMembers: build.query<GetMembersResponse, Partial<GetMembersDto>>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
    }),
    getMember: build.query<Member, string>({
      query: (id) => ({
        url: "/" + id,
        method: "GET",
      }),
    }),
    updateMemberAssignee: build.mutation<any, { teacher_id: string; batch_id: string }>({
      query: (body) => ({
        url: "/update-assignee",
        method: "PUT",
        body,
      }),

      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", baseQueryReturnValue);
      },
    }),
    deleteMemberAssignee: build.mutation<any, string>({
      query: (id) => ({
        url: "/delete-assignee/" + id,
        method: "DELETE",
      }),

      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", baseQueryReturnValue);
      },
    }),
  }),
});

export const {
  useCreateMemberMutation,
  useGetMembersQuery,
  useGetMemberQuery,
  useUpdateMemberMutation,
  useDeleteMemberMutation,
  useUpdateMemberAssigneeMutation,
  useDeleteMemberAssigneeMutation,
} = memberApi;
