import React, { ChangeEvent } from "react";
import { twMerge } from "tailwind-merge";

interface IInput extends React.HTMLProps<HTMLInputElement> {
  id: string;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  placeholder?: string;
  type?: string;
  label?: any;
  value?: any;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  readonly?: boolean;
  textColor?: string;
  disabled?: boolean;
}

export const Input = React.forwardRef(
  (
    {
      id,
      className = "",
      inputClassName = "",
      labelClassName = "",
      placeholder,
      type = "text",
      label,
      value,
      onChange,
      onBlur,
      readonly = false,
      textColor = "text-dark-blue",
      disabled = false,
      ...props
    }: IInput,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div className={twMerge("flex flex-col items-start", className)}>
        <label className={twMerge("w-full text-gray-500 text-sm", labelClassName)} htmlFor={id}>
          {label}
        </label>
        {!readonly ? (
          <input
            id={id}
            className={twMerge("w-full rounded-md p-2 border disabled:opacity-30", inputClassName)}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={(e) => {
              if (onChange) onChange(e);
            }}
            disabled={disabled}
            onBlur={(e) => {
              if (onBlur) onBlur(e);
            }}
            {...props}
            ref={ref}
          />
        ) : (
          <span className={"text-lg " + textColor}>{value || "-"}</span>
        )}
      </div>
    );
  }
);
