import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { Payment } from "../../interfaces";
import { toastMessage } from "../../utils/toastError";

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/admin/payment",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getPayments: build.query<{ payments: Payment[]; total: number }, Partial<any>>({
      query: (params) => ({
        url: "/all",
        method: "GET",
        params,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
    getPaymentDetails: build.query<Payment, string>({
      query: (id) => ({
        url: "/details/" + id,
        method: "GET",
      }),
    }),
    getReport: build.query<any, Partial<any>>({
      query: (params) => ({
        url: "/report",
        method: "GET",
        params,
      }),

      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
  }),
});

export const { useGetPaymentsQuery, useLazyGetReportQuery, useLazyGetPaymentDetailsQuery } = paymentApi;
