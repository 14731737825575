/* eslint-disable no-unused-vars */

export enum StudentMode {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
}

export enum BatchType {
  BATCH = "BATCH",
  COURSE = "COURSE",
}

export enum ActivityActionType {
  UPDATE = "UPDATE",
  READ = "READ",
  CREATE = "CREATE",
  DELETE = "DELETE",
  DOWNLOAD = "DOWNLOAD",
  UPLOAD = "UPLOAD",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  REQUEST = "REQUEST",
}

export enum UserType {
  ADMIN = "admin",
  STUDENT = "student",
}

export enum AppModule {
  AUTH = "AUTH",
  BATCH = "BATCH",
  STUDENT = "STUDENT",
  PAYMENT = "PAYMENT",
  FULFILMENT = "FULFILMENT",
  REQUEST = "REQUEST",
  BIRTHDAY = "BIRTHDAY",
  INVENTORY = "INVENTORY",
  MEMBER = "MEMBER",
  CATEGORY = "CATEGORY",
}

export enum ClassStatus {
  STARTED = "STARTED",
  ENDED = "ENDED",
  NOT_STARTED = "NOT_STARTED",
}

export enum ContentCategory {
  STUDY_MATERIAL = "STUDY_MATERIAL",
}

export enum ContentType {
  FOLDER = "FOLDER",
  FILE = "FILE",
}

export enum ContentFileType {
  VIDEO = "VIDEO",
  DOCUMENT = "DOCUMENT",
}

export enum Fulfilment {
  UNFULFILLED = "UNFULFILLED",
  FULFILLED = "FULFILLED",
}

export enum RequestStatus {
  OPEN = "OPEN",
  PROCESSING = "PROCESSING",
  RESOLVED = "RESOLVED",
  CLOSED = "CLOSED",
}

export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  MANAGER = "MANAGER",
  TEACHER = "TEACHER",
  STUDENT = "STUDENT",
}

export enum CategoryType {
  STUDENT = "STUDENT",
  BATCH = "BATCH",
  REQUEST = "REQUEST",
}

export enum DifficultyLevel {
  EASY = "EASY",
  MEDIUM = "MEDIUM",
  HARD = "HARD",
}

export enum TestStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  PUBLISHED = "PUBLISHED",
  COMPLETED = "COMPLETED",
  UPCOMING = "UPCOMING",
  ASSIGNED = "ASSIGNED",
  ONGOING = "ONGOING",
}
