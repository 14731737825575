import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import {
  CategoryTypes,
  Chapter,
  CreateQuestionDto,
  GetQuestionDto,
  Question,
  ListResponse,
  Subject,
  Tag,
  UpdateQuestionDto,
} from "../../interfaces";
import { PaginationDto } from "../../interfaces/dto/default.dto";
import { toastMessage } from "../../utils/toastError";

export const questionApi = createApi({
  reducerPath: "questionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/question",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getQuestion: build.query<Question, string>({
      query: (id) => ({
        url: "/" + id,
        method: "GET",
      }),
    }),
    getQuestions: build.query<ListResponse<Question>, GetQuestionDto>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
    }),
    getQuestionStats: build.query<{ questions: number; subjects: number; chapters: number }, void>({
      query: () => ({
        url: "/stats",
        method: "GET",
      }),
    }),
    createQuestion: build.mutation<Question, CreateQuestionDto>({
      query: (payload) => ({
        url: "/",
        method: "POST",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Question) {
        toastMessage("success", "Successfully created");
        return baseQueryReturnValue;
      },
    }),
    updateQuestion: build.mutation<Question, UpdateQuestionDto>({
      query: (payload) => ({
        url: "/",
        method: "PUT",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Question) {
        toastMessage("success", "Successfully updated");
        return baseQueryReturnValue;
      },
    }),

    deleteQuestion: build.mutation<any, string[]>({
      query: (ids) => ({
        url: "/",
        method: "DELETE",
        body: { ids },
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully deleted");
        return baseQueryReturnValue;
      },
    }),
    getSubjects: build.query<ListResponse<Subject>, PaginationDto | void>({
      query: (params) => ({
        url: "/subject",
        method: "GET",
        params: params ? params : {},
      }),
    }),
    createSubject: build.mutation<Subject, { name: string; description?: string }>({
      query: (payload) => ({
        url: "/subject",
        method: "POST",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Subject) {
        toastMessage("success", "Successfully created");
        return baseQueryReturnValue;
      },
    }),
    updateSubject: build.mutation<CategoryTypes, { id: string; name: string }>({
      query: (payload) => ({
        url: "/subject",
        method: "PUT",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
    }),
    deleteSubject: build.mutation<any, string | number>({
      query: (id) => ({
        url: "/subject",
        method: "DELETE",
        body: { id },
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully deleted");
        return baseQueryReturnValue;
      },
    }),
    getChapters: build.query<ListResponse<Chapter>, { subject_id: number | string }>({
      query: (params) => ({
        url: "chapter",
        method: "GET",
        params,
      }),
    }),
    createChapter: build.mutation<Chapter, { name: string; subject_id: number | string }>({
      query: (payload) => ({
        url: "chapter",
        method: "POST",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Chapter) {
        toastMessage("success", "Successfully created");
        return baseQueryReturnValue;
      },
    }),
    updateChapter: build.mutation<Chapter, { id: string; name: string }>({
      query: (payload) => ({
        url: "chapter",
        method: "PUT",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Chapter) {
        toastMessage("success", "Successfully updated");
        return baseQueryReturnValue;
      },
    }),
    deleteChapter: build.mutation<any, string | number>({
      query: (id) => ({
        url: "chapter",
        method: "DELETE",
        body: { id },
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully deleted");
        return baseQueryReturnValue;
      },
    }),
    getAllTags: build.query<ListResponse<Tag>, void>({
      query: () => ({
        url: "tag",
        method: "GET",
      }),
    }),
    createTag: build.mutation<Tag, { name: string }>({
      query: (payload) => ({
        url: "tag",
        method: "POST",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.resolve(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Tag) {
        toastMessage("success", "Successfully created");
        return baseQueryReturnValue;
      },
    }),
    updateTag: build.mutation<Tag, { id: string; name: string }>({
      query: (payload) => ({
        url: "tag",
        method: "PUT",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Tag) {
        toastMessage("success", "Successfully updated");
        return baseQueryReturnValue;
      },
    }),
    deleteTag: build.mutation<Tag, { id: string }>({
      query: (payload) => ({
        url: "tag",
        method: "DELETE",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue: Tag) {
        toastMessage("success", "Successfully deleted");
        return baseQueryReturnValue;
      },
    }),
  }),
});

export const {
  // Subjects
  useCreateSubjectMutation,
  useUpdateSubjectMutation,
  useDeleteSubjectMutation,
  useGetSubjectsQuery,
  // Chapters
  useCreateChapterMutation,
  useUpdateChapterMutation,
  useDeleteChapterMutation,
  useLazyGetChaptersQuery,
  // Tags
  useGetAllTagsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
  // Questions
  useCreateQuestionMutation,
  useGetQuestionsQuery,
  useGetQuestionStatsQuery,
  useGetQuestionQuery,
  useLazyGetQuestionQuery,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation,
} = questionApi;
