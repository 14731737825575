import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { DashboardResponse } from "../../interfaces";

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/admin/dashboard",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getDashboardData: build.query<DashboardResponse, void>({
      query: () => ({
        url: "/",
        method: "GET",
        cache: "reload",
      }),
    }),
  }),
});

export const { useGetDashboardDataQuery } = dashboardApi;
