import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { toastMessage } from "../../utils/toastError";
import { API_ENDPOINT } from "../../config";
import {
  CreatePerformanceDto,
  GetPerformanceDto,
  UpdatePerformanceDto,
  PerformanceResponse,
  PerformanceResponseStudent,
} from "../../interfaces";

export const performanceApi = createApi({
  reducerPath: "performanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/performance",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getPerformances: build.query<PerformanceResponse, GetPerformanceDto>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
    }),
    getStudentPerformances: build.query<PerformanceResponseStudent, GetPerformanceDto>({
      query: (params) => ({
        url: "/student",
        method: "GET",
        params,
      }),
    }),

    performanceReport: build.query<string, GetPerformanceDto>({
      query: (params) => ({
        url: "/report",
        method: "GET",
        params,
      }),
    }),

    createPerformance: build.mutation<any, CreatePerformanceDto>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully created");
        return baseQueryReturnValue;
      },
    }),

    addPerformanceMark: build.mutation<any, { student_id: string; mark: number; performance_id: string }>({
      query: (body) => ({
        url: "/mark",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully created");
        return baseQueryReturnValue;
      },
    }),

    updatePerformance: build.mutation<any, UpdatePerformanceDto>({
      query: ({ id, ...body }) => ({
        url: "/" + id,
        method: "PUT",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully updated");
        return baseQueryReturnValue;
      },
    }),
    deletePerformance: build.mutation<any, string>({
      query: (id) => ({
        url: "/" + id,
        method: "DELETE",
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
      transformResponse(baseQueryReturnValue) {
        toastMessage("success", "Successfully deleted");
        return baseQueryReturnValue;
      },
    }),
  }),
});

export const {
  useCreatePerformanceMutation,
  useUpdatePerformanceMutation,
  useGetPerformancesQuery,
  useGetStudentPerformancesQuery,
  useLazyPerformanceReportQuery,
  useDeletePerformanceMutation,
  useAddPerformanceMarkMutation,
} = performanceApi;
